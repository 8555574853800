/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import React, { Suspense, useEffect, useState } from "react"
import PreLoader from "./src/components/PreLoader"
import "./src/styles/global.css"
import "./src/utils/gsap"
import gsap from "./src/utils/gsap"
import { useGSAP } from "@gsap/react"

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (!prevLocation && !location.hash) {
    // do not restore scroll position on page refresh without hash
    setTimeout(
      () => window.scrollTo({ top: 0, left: 0, behavior: "instant" }),
      0
    )
  }
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  window.history.scrollRestoration = "manual"
  const currentPosition = getSavedScrollPosition(location) || [0, 0]

  setTimeout(() => {
    if (location.hash) {
      // If hash (`/#something`), smooth scroll to that position
      const item = document.querySelector(`${location.hash}`)
      item?.scrollIntoView({ behavior: "smooth" })
    } else {
      // when using the browser back/forward buttons or navigating to a different page client-side, instant scroll to its last saved position
      window.scrollTo({
        top: currentPosition[1],
        left: currentPosition[0],
        behavior: "instant",
      })
    }
  }, 0)

  // do not let gatsby do anything more
  return false
}

const close = () => {
  const closing = gsap.timeline()
  closing.to("#percent, #bar, #loading", {
    duration: 0.9,
    autoAlpha: 0,
    zIndex: -1,
  })
  closing.to("#loader, #container-loader", {
    autoAlpha: 0,
    duration: 1.25,
    ease: "power1.inOut",
  })
}
function step(width: number) {
  gsap.to("#bar", {
    width: width + "%",
    duration: 2.5,
    ease: "expo.out",
  })
  gsap.to("#percent", {
    xPercent: width - 5,
    duration: 2.5,
    ease: "expo.out",
  })
}

// export const onInitialClientRender = () => {
const onLoad = () => {
  let width = 0
  let id
  function move() {
    id = setInterval(frame, 25)
  }
  function frame() {
    if (width >= 100) {
      clearInterval(id)
      close()
    } else {
      width++
      step(width)
      if (document?.getElementById?.("percent")?.innerHTML) {
        document.getElementById("percent").innerHTML = width + "%"
      }
    }
  }
  move()
}

export const wrapPageElement = ({ element, props }) => {
  useGSAP(() => {
    onLoad();
  })
  return (
    <>
      <PreLoader />
      {element}
    </>
  )
}
