import React from "react"

const PreLoader = () => {
  return (
    <div id="container-loader" className="fixed z-50 inset-0 flex justify-center items-center bg-white text-black">
      <div id="loader" className="relative flex flex-col justify-center items-center w-full h-full">
        <div className="flex gap-3 flex-col justify-center items-start w-full pt-[30vh] mx-auto h-full z-50">
          <h1 className="w-full" id="percent">
            0%
          </h1>
          <div className="text-dark font-[15px] border border-neutral w-0" id="bar"></div>
          <div id="loading" className="px-20">Loading...</div>
        </div>
      </div>
    </div>
  )
}

export default PreLoader;